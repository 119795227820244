import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import { LinkModal } from 'kh-common-components';
import { Svg } from 'kh-common-components';

import Link from 'components/common/Link';
import Faq from 'components/common/Faq';

import BlogCarousel from 'components/cards/BlogCarousel';

import { modalContents } from 'constants/modal-content';
import faqData from 'constants/components/accordion-list/business-faq';
import businessProducts from 'constants/components/carousel/business-products-carousel';

import heroBanner from 'images/business/hero-business-sign.png';

import bulBiz from 'images/business/biz-bulk.jpg';
import eleBiz from 'images/business/biz-ele.jpg';
import lngBiz from 'images/business/biz-lng.jpg';
import lpgBiz from 'images/business/biz-lpg.jpg';
import natBiz from 'images/business/biz-ng.jpg';
import speBiz from 'images/business/biz-spec.jpg';

import logoVarsity from 'images/business/logo-varsity.png';
import logoWindward from 'images/business/logo-windward.png';



function BusinessPage() {

	const products = businessProducts.map(object => {
		return {...object, 
			mediaSrc: object.id === 'bul' ? bulBiz : 
					  object.id === 'ele' ? eleBiz :
					  object.id === 'lng' ? lngBiz :
					  object.id === 'lpg' ? lpgBiz :
					  object.id === 'nat' ? natBiz :
					  object.id === 'spe' ? speBiz : ''}
	});

	return (
		<>
			<SEO title="Supporting local business with tailored energy solutions"
				 description="Join the growing number of industrial and commercial customers enjoying better value with Kleenheat, WA’s local energy supplier." />

            <Header />

        	<LayoutMain>

	    		{/* hero banner */}
	    		<LayoutPanel theme="mer-theme--cyan"
		                     background="mer-bg--primary-cyan"
		                     padding="mer-py-lg">
	                <div className="container">
	                    <div className="row mer-panel align-items-center justify-content-center">

	                        <div className="col-10 col-md-6 mer-text--size-lg offset-md-1 order-md-2">
	                            <div className="content">
	                                <h2 className="mb-0">Supporting local business with tailored energy solutions</h2>
									<p>Join the growing number of industrial and commercial customers enjoying better value with Kleenheat, WA’s local energy supplier.</p>
									<h6 className="mt-2">Request an obligation-free quote to see how much you could save.</h6>

									<div className="mer-panel__actions mer-button-group">
										<Link text="Request a quote"
											  link="/business/get-a-quote"
										 	  linkClass="mer-button mer-button--primary" />
										
										<Link text="Case studies"
											  link="#case-study"
											  linkClass="mer-button mer-button--secondary" />
									</div>
	                            </div>
	                            
	                        </div>

	                        <div className="mer-panel__images hero-banner col-8 col-md-4 col-xl-4 order-md-1">
	                            <img src={heroBanner} 
	                                 className="mer-panel__image"
	                                 alt="Come in we're open sign" />
	                        </div>

	                        
	                    </div>
	                </div>
	            </LayoutPanel>

				{/* proof */}
				<LayoutPanel padding="mer-py-lg"
							 background="mer-bg--ui-light"
							 id="proof">
					<div className="container">
						<div className="row justify-content-md-around justify-content-lg-between justify-content-center">
							<div className="col-10 col-md-3 d-flex flex-column align-items-stretch">
								<div className="content">
									<div className="col-6 col-md-9 col-lg-12 mx-auto">
										<Svg title="Flowmoji WA NT"
											col="mer-panel__image"
											icon="flowmoji-wa-nt"
										/> 
									</div>
									<h4 className="mer-typography--headline5 mt-0">Value in choosing local.</h4>
									<p>We deliver energy savings to more than 10,000 local businesses across WA and the NT, while supporting the communities that support us.</p>
								</div>
							</div>
							<div className="col-10 col-md-3 d-flex flex-column align-items-stretch">
								<div className="content">
									<div className="col-6 col-md-9 col-lg-12 mx-auto">
										<Svg title="Flowmoji Piggybank"
											col="mer-panel__image"
											icon="flowmoji-piggybank"
										/> 
									</div>
									<h4 className="mer-typography--headline5 mt-0">Pricing tailored to your business.</h4>
									<p>Our experienced team works with you to understand your priorities and offer energy solutions that best support your business.</p>
								</div>
							</div>
							<div className="col-10 col-md-3 d-flex flex-column align-items-stretch">
								<div className="content">
									<div className="col-6 col-md-9 col-lg-12 mx-auto">
										<Svg title="Flowmoji Agent"
											col="mer-panel__image"
											con="mer-fill--primary-navy-dark"
											icon="flowmoji-agent"
										/> 
									</div>
									<h4 className="mer-typography--headline5 mt-0">Dedicated account management.</h4>
									<p>Our dedicated Account Managers are available to provide you with energy solutions that suit your business with timely, professional customer service.</p>
								</div>
							</div>
						</div>
					</div>
				</LayoutPanel>

				<LayoutPanel padding="mer-pt-lg pb-0"
							 border="mer-border--top"
							 background="mer-bg--gs-grey-lighter"
							 id="products">
					<div className="container">
	                    <div className="row mer-panel">
							<div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg text-center">
	                            <div className="content">
	                                <h2>Energy solutions for business</h2>
	                                <p>Our range of energy solutions are suited for businesses of any size.</p>
	                            </div>
	                        </div>
						</div>
					</div>
				</LayoutPanel>

				<BlogCarousel blogData={products}
							  merBtnLinkClass="mer-width-100"
							  hidePanelImgBg={true}
							  panelBg="mer-bg--gs-grey-lighter"
							  panelSpacing="mer-pb-lg"
							  panelBorder="mer-border--bottom" />

	        	{/* main usp panel */}
	            <LayoutPanel padding="mer-pt-lg pb-0"
							 id="case-study"
							 background="mer-bg--ui-light">
	                <div className="container">
	                    <div className="row mer-panel">
	                        <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg text-center">
	                            <div className="content">
	                                <h1 className="mer-color--primary-cyan">
	                                	Locals supporting locals, <span className="mer-text--no-wrap">it's what we do.</span>
	                                </h1>
	                                <p>Read our business case studies to see how we've helped put the energy into local business.</p>
	                            </div>
	                        </div>
	                    </div>

						<div className="row justify-content-center">
							<div className="col-10 col-sm-8">
								<div className="row mer-py-de">
									<div className="mer-panel__images col-5 mx-auto col-sm-3 my-auto mr-lg-5">
										<img className="mer-panel__image"
											 src={logoVarsity}
											 alt="Varsity logo" />
									</div>
									<div className="content col-12 col-sm-7 offset-sm-1 offset-lg-0">
										<h3>Varsity Group</h3>
										<p>Operating six American-style sports bars across Perth, Varsity Group rely on Kleenheat to deliver reliable supply of natural gas and electricity so they can deliver delicious burgers and cold beer to their customers.</p>
										<LinkModal
											modalContents={modalContents}
											useModal="true"
											displayContent="Watch the video"
											modalContentName="business_case_study_varsity"
											title=""
										/>
									</div>
								</div>
								<div className="row mer-border--top mer-pt-de mer-pb-ju" style={{borderTopStyle: 'dashed'}}>
									<div className="mer-panel__images col-5 mx-auto col-sm-3 my-auto mr-lg-5">
										<img className="mer-panel__image"
											 src={logoWindward}
											 alt="Windward logo"/>
									</div>
									<div className="content col-12 col-sm-7 offset-sm-1 offset-lg-0">
										<h3>Windward Balloon Adventures</h3>
										<p>Locally owned and operated, Windward Balloon Adventures has been operating in Western Australia for over 30 years. Kleenheat is proud to supply the gas to deliver a safe and unforgettable hot air ballooning experience for their customers.</p>
										<LinkModal
											modalContents={modalContents}
											useModal="true"
											displayContent="Watch the video"
											modalContentName="business_case_study_windward"
											title=""
										/>
									</div>
								</div>
							</div>
						</div>
	                </div>
	            </LayoutPanel>

			  	<Faq panelBackground="mer-bg--gs-grey-lighter"
		  			 panelPadding="mer-py-lg"
					 id="faq"
					 border="mer-border--top mer-border--bottom"
		  			 data={faqData} />

  			    <BlogCarousel id="stories"
            			  	  title="Business customer stories"
							  titleCol="col-6"
            			  	  apiQuery="?tag=business"
    			/>
        	</LayoutMain>
            <Footer />
		</>
		
	);
}

export default BusinessPage;
